.users-management-container h2{
    font-size: 30px;
    margin-top: 20px;
    text-align: center;
    color: #20448A;
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    .users-management-container {
      padding: 10px;
    }
  
    .users-management-container h2 {
      font-size: 18px;
    }
  }