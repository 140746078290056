.payment-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #1a1a1a;
    color: #ffffff;
    padding: 20px;
}

.payment-success-card {
    background-color: #333333;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.success-icon {
    font-size: 80px;
    color: #52c41a;
}

.success-title {
    color: #ffffff;
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.success-message {
    color: #d9d9d9;
    margin: 15px 0;
    font-size: 16px;
}

.return-button {
    background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%) ;
    color: #ffffff;
    width: 100%;
    margin-top: 20px;
    font-weight: bold;
}

.return-button:hover {
    background: linear-gradient(to bottom, #7d0222 0%, #F90343 100%) !important;
}
