.movie-watch {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #0d0d0d;
    position: relative;
}

.movie-watch h2 {
    font-size: 2.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #e0e0e0;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 2px solid #e50914;
    padding-bottom: 5px;
}

.movie-watch p {
    color: #bbb;
    font-size: 1rem;
    text-align: center;
}

.movie-select-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.movie-select {
    padding: 12px 30px;
    font-size: 1rem;
    border-radius: 5px;
    border: 2px solid #333;
    background-color: #2b2b2b;
    color: #e0e0e0;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    outline: none;
    width: 100%;
    max-width: 300px;
}

.movie-select:hover {
    border-color: #b30b0b;
}

.movie-watch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0d0d0d;
}

.movie-watch-container p {
    color: #fff;
    font-size: 1.2rem;
}

.movie-watch-container video {
    max-width: 100%;
}

.watermark-email {
    position: absolute;
    top: 17%;
    left: 24%;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    z-index: 10;
    pointer-events: none;
    padding: 10px;
}

.player-wrapper {
    position: relative;
    max-width: 800px;
    margin: 20px auto;
    text-align: center;
    padding: 20px;
}

.player-overlay {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .movie-watch h2 {
        font-size: 1.8rem;
    }

    .movie-select-container {
        max-width: 250px;
    }

    .player-wrapper {
        max-width: 90%;
        padding: 10px;
    }

    .movie-watch-container p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .movie-watch h2 {
        font-size: 1.5rem;
    }

    .movie-select-container {
        max-width: 200px;
    }

    .movie-select {
        font-size: 0.9rem;
        padding: 10px;
    }

    .movie-watch-container .player-wrapper {
        padding: 5px;
        margin-top: -40px;
    }

    .player-wrapper .react-player {
        width: 100% !important;
    }

    .movie-watch-container p {
        font-size: 0.9rem;
    }

    .watermark-email {
        font-size: 12px;
        top: 15%;
        left: 5%;
    }
}