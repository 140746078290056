@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

.footer {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: radial-gradient(circle at 3% 25%, rgba(255, 255, 0, 1) 0%, rgb(51, 51, 55) 0%, rgb(4, 4, 4) 100%); */
    background-color: #000;
}

.footer-heading {
    width: 100%;
    text-align: center;
}

.footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 60px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
    background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 2rem;
}

.footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 4vh;
    cursor: pointer;
}

.footer-btn p {
    box-sizing: border-box;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: white;
    word-spacing: 2px;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 90%;
    text-align: center;
}

.footer-links div {
    width: 250px;
}

.footer-links_logo {
    display: flex;
    flex-direction: column;
}

.footer-link_logo img {
    width: 200px;
}

.footer-link_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.footer-link_div h4 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 2rem;
}

.footer-link_div p {
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    margin: 0.5rem 0;
    cursor: pointer;
    transition: color 0.3s ease;
}

.footer-link_div p:hover {
    color: #b30b0b !important;
}

.footer-copyright {
    text-align: center;
    width: 100%;
}

.footer-copyright p {
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 14px;
    color: #fff;
    margin-bottom: 5vh;
}

.footer-social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer-social-icons a {
    color: #ffffff;
    font-size: 30px;
    transition: color 0.3s ease;
}

.footer-social-icons a:hover {
    color: #b30b0b;
}

.footer-divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
}

.footer-app-download {
    text-align: center;
    margin: 20px 0 0 0;
}

.footer-app-download p{
    font-size: 20px !important;
    /* line-height: 25px; */
    background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 2rem;
}

/* .footer-app-download img {
    width: 200px;
    max-width: 100%;
} */

.footer-copyright p {
    font-size: 15px;
    line-height: 25px;
}

/* Responsive styles */

@media screen and (max-width: 850px) {
    .footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .footer-links div {
        margin: 1rem 0;
        width: 100%;
    }

    .footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 480px) {
    .footer-heading h1 {
        font-size: 35px;
        line-height: 40px;
        margin: auto;
    }

    .footer-copyright p {
        font-size: 14px;
        line-height: 30px;
    }

    .footer-social-icons a:hover {
        color: #b30b0b;
    }
}