@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Donegal+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.movie-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0d0d0d;
  color: white;
  padding: 20px;
}

.movie-info {
  flex: 1;
  padding-right: 20px;
}

.movie-info h1 {
  font-size: 36px;
  margin: 20px;
}

.movie-info p {
  margin-bottom: 10px;
  font-size: 14px;
}

.movie-info .movie-rating {
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 20px;
  width: 33%;
}

.movie-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.watch-trailer {
  border: none;
  border-radius: 20px;
  background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%);
  color: white;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
}

.watch-trailer:hover {
  background: linear-gradient(to bottom, #7d0222 0%, #F90343 100%);
}

.ticket {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
}

.movie-info .movie-actions p {
  margin: 10px 0 0 10px;
  font-size: 18px;
}

.movie-poster {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.poster-container {
  position: relative;
  max-width: 100%;
}

.movie-poster img {
  max-width: 100%;
  border-radius: 10px;
  transition: filter 0.3s ease;
}

.movie-details-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(5, 13, 20, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.poster-container:hover img {
  filter: blur(5px);
}

.poster-container:hover .movie-details-overlay {
  opacity: 1;
}

.ticketImg {
  width: 150px;
  height: 80px;
  margin-top: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ticketImg:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 50%;
  max-height: 60%;
  overflow: hidden;
}

.modal-content video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: -10px;
  right: 5px;
  font-size: 2rem;
  color: #333;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}

@media (max-width: 1024px) {
  .movie-actions {
    padding-left: 20px;
    flex-direction: column;
  }

  .watch-trailer,
  .ticket {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .movie-info {
    padding-right: 10px;
  }
}

@media (max-width: 768px) {
  .movie-details {
    flex-direction: column;
    padding: 15px;
  }

  .movie-info {
    padding-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .movie-poster {
    width: 100%;
    margin-bottom: 20px;
  }

  .movie-poster img {
    max-width: 80%;
  }

  .movie-actions {
    padding-left: 0;
    justify-content: center;
  }

  .movie-info .movie-rating {
    width: 100%;

  }
}

@media (max-width: 480px) {
  .movie-info h1 {
    font-size: 28px;
  }

  .movie-info .movie-rating p {
    font-size: 14px;
  }

  .movie-poster img {
    min-width: 100%;
    min-height: 250px;
  }

  .movie-info .movie-rating {
    border: 1px solid white;
    border-radius: 10px;
    margin: 0 10px 10px 0;
  }

  .modal-content {
    max-width: 90% !important;
  }

  .modal-content video {
    width: 100% !important;
    /* max-height: 50% !important; */
    /* padding: 0 !important; */
    /* height: auto !important; */
  }

  .watch-trailer {
    width: 100%;
    background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%) !important;
  }

  .watch-trailer:hover {
    background: linear-gradient(to bottom, #7d0222 0%, #F90343 100%) !important;
  }
}