@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body{
  font-family: 'DM Sans', sans-serif;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
  }
  
  .overlay {
    /* height: 100%; */
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
  }
  
  .login img {
    width: 100%;
    height: 100vh;
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .login-heading{
    color: white;
    font-size: 24px;
    margin-bottom: 30px;
    text-align: left;
  }

  .login-container {
    margin: 10px 0;
  }
  
  .login-box {
    background: rgba(0, 0, 0, 0.8);
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    width: 600px;
    height: 450px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    z-index: 1;
    border: 2px solid #ffffff;
    margin-top: -50px;
  }
  
  .google-login{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #121418;
    color: white;
    padding: 10px;
    margin-left: 80px;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
    width: 70%;
  }
  
  .google-login .icon {
    margin-right: 20px;
    font-size: 30px;
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 15px 150px;
    color: #ccc;
  }
  
  .separator span {
    padding: 0 15px;
    background: rgba(0, 0, 0, 0.8);
    font-size: 40px;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
  }
  
  .input-field-login {
    width: 90%;
    padding: 20px;
    margin: 20px 30px;
    height: 50px;
    border-radius: 5px;
    background: #121418;
    text-align: left;
    color: white;
  }
  
  .form-details{
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    justify-content: center;
    margin-bottom: 20px;
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%; 
    margin: 20px 30px; 
    color: #ffffff;
  }
  
  .checkbox {
    display: flex;
    align-items: center;
  }
  
  .checkbox input {
    margin-right: 5px;
  }
  
  .options a {
    color: #ffffff;
  }
  
  .forgot-password {
    text-decoration: none;
  }
  
  .signin-button {
    background: #20448A;
    color: white;
    padding: 20px 40px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 40px;
    z-index: 1;
    border: 1px solid #ffffff;
  }
  
  .register-link {
    color: #ccc;
    margin-top: 20px;
    font-size: 20px;
  }
  
  .reg-btn{
    width: 80px;
    height: 30px;
    margin-left: 40px;
    font-size: 15px;
    background-color: #692726;
    color: #ffffff;
    
  }

  /* Media Queries */

@media (min-width: 768px) {

  .google-login {
    width: 70%;
    font-size: 16px;
  }

  .separator {
    margin: 15px 150px;
  }

  .input-field-login {
    padding: 20px;
    font-size: 16px;
  }

  .signin-button {
    padding: 20px 40px;
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .login-box {
    width: 90%;
    padding: 40px;
  }

  .login-heading {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .google-login {
    font-size: 14px;
    padding: 8px;
    margin-left: 60px;
  }

  .google-login .icon {
    font-size: 24px;
    margin-right: 10px;
  }

  .separator {
    margin: 10px 0;
    padding: 20px;
  }

  .separator span {
    font-size: 16px;
    padding: 0 10px;
  }

  .input-field-login {
    padding: 15px;
    font-size: 14px;
  }

  .signin-button {
    padding: 15px 30px;
    font-size: 16px;
  }

  .register-link {
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .login-box {
    width: 100%;
    padding: 10px;
  }

  .login-heading {
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
  }

  .google-login {
    font-size: 15px;
    padding: 6px;
    margin-left: 60px;
    margin-top: 20px;
  }

  .google-login .icon {
    font-size: 20px;
    margin-right: 8px;
  }

  .separator span {
    font-size: 15px;
    padding: 0 8px;
  }

  .input-field-login {
    padding: 10px;
    margin: 10px 20px;
    font-size: 15px;
  }

  .terms-container {
    margin: 8px 0;
    font-size: 15px;
  }

  .signin-button {
    padding: 8px 16px;
    margin-top: 120px;
    font-size: 12px;
    width: 100%;
    max-width: 120px;
  }
}
