.live-movie-card{
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin-left: 4rem;
  margin-right: 3rem;
}

.live-movie-card img {
  width: 100%;
  border-radius: 10px;
}

@media screen and (max-width: 480px) {
  .movie-management-container {
    flex-direction: column;
    align-items: center;
  }
  .movie-management-details{
    padding: 20px;
    width: 140%;
    margin: 10px 10px;
  }

  .form-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .btn-movie-management {
    width: 100%;
  }
}
