.centered-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.movie-actions {
  text-align: center;
}

.frame {
  border: 3px solid #000; /* Adjust the color and width as needed */
  padding: 40px; /* Adjust the padding as needed */
  border-radius: 10px; /* Optional: for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: for a subtle shadow */
}

.payment-container {
  background-color: #f2f2f2;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-card {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-title {
  text-align: center;
  margin-bottom: 20px !important;
  color: #1890ff;
}

.payment-methods {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.payment-logo {
  width: 70px;
  height: auto;
  transition: transform 0.3s;
}

.payment-logo:hover {
  transform: scale(1.1);
}

.confirm-payment-btn:hover {
  background: linear-gradient(90deg, #40a9ff 0%, #69c0ff 100%);
}

.payment-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.payment-image {
  text-align: center;
}

.slider-image {
  width: 100%;
  height: auto;
  max-height: 550px;
  object-fit: cover;
}

.centered-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive adjustments */

@media (max-width: 768px) {
  .payment-container {
    padding: 20px;
  }

  .payment-title {
    font-size: 24px;
  }

  .payment-logo {
    width: 30px; 
    height: 20px; 
  }

  .confirm-payment-btn {
    font-size: 14px;
    padding: 8px;
  }

  .payment-image {
    order: 1;
    margin-bottom: 20px;
  }

  .payment-details {
    order: 2;
  }
}

@media (max-width: 480px) {
  .payment-container {
    padding: 15px;
  }

  .payment-title {
    font-size: 20px;
  }

  .payment-logo {
    width: 25px; 
    height: 18px; 
  }

  .confirm-payment-btn {
    font-size: 12px;
    padding: 6px;
  }

  .payment-image {
    order: 1;
    margin-bottom: 20px;
  }

  .payment-details {
    order: 2;
  }
}