@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.navbar {
    font-family: 'DM Sans', sans-serif;
    width: 100%;
    background-color: #000;
    padding: 1px;
    position: relative;
    top: 0;
    z-index: 1000;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.9);
}

.navbar-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    color: #ffffff;
}

.navbar-links_logo img {
    display: flex;
    width: 200px;
}

.navbar-links-container {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.navLists {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
}

.navItem {
    position: relative;
    margin-top: 10px;
    font-family: 'DM Sans', sans-serif;
    margin-left: 50px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.navItem:hover {
    color: #b30b0b;
}

.navItem.active {
    color: #ffffff;
}

.navItem:first-child {
    margin-left: 0;
}

.dropdown-icon {
    margin-left: 5px;
    cursor: pointer;
}

.subNavLists {
    position: fixed;
    background-color: #0d0d0d;
    display: none;
    list-style: none;
    padding: 10px 0;
    /* margin: 0; */
    margin-top: 5px;
    z-index: 1000;
    border-radius: 10px;
}

.navItem:hover .subNavLists,
.navItem.active .subNavLists {
    display: block;
}

.subNavItem {
    padding: 8px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.subNavItem:hover {
    color: #b30b0b;
}

.subNavItem.active {
    color: #ffffff;
}

.signin-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 0;
}

.signin {
    margin-right: 20px;
    font-family: 'DM Sans', sans-serif;
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease-in-out;
}

.signin p {
    margin: 0;
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 10px;
    font-size: 24px;
}

.mobileMenu {
    display: none;
    position: absolute;
    right: 100px;
    top: 40px;
    z-index: 2000;
    background-color: #20448A;
}

.mobileMenu .ant-btn {
    color: white;
    font-size: 30px;
    border: none;
}

.navLists .ant-drawer-close {
    color: black !important;
    position: absolute;
    font-size: 24px;
}



/* Responsive Design */
@media screen and (max-width: 768px) {
    .navLists {
        flex-direction: column;
        align-items: center;
    }

    .navItem {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .dropdown-icon {
        margin-left: 5px;
        cursor: pointer;
    }
}

@media screen and (max-width: 480px) {
    .navbar-links-container .navLists {
        flex-direction: column;
        align-items: start;
        display: none;
    }

    .navbar-links_logo {
        position: relative;
        width: 100%;
    }

    .navItem {
        margin-left: 0;
        right: 15px;
        margin-bottom: 10px;
    }

    .navbar-links .mobileMenu {
        display: flex;
        background-color: #000;
    }

    .navItem:hover {
        color: #b30b0b;
    }

    .subNavLists {
        /* position: relative; */
        background-color: #fff;
        color: #000;
        display: none;
        list-style: none;
        padding: 10px 0;
        margin: 0;
        z-index: 1000;
        border-radius: 10px;
    }

    .subNavItem:hover {
        color: #b30b0b;
    }

    .dropdown-icon {
        margin-left: 5px;
        cursor: pointer;
    }

    .navbar-links-container {
        display: none;
    }

    .signin-container {
        padding: 10px 0;
        text-align: center;
    }

    .signin-container p {
        display: none;
    }

    .mobileMenu::after {
        display: flex;
        /* background-color: #000; */
    }

    .mobileMenu .ant-btn {
        color: white;
    }

    .ant-drawer-close {
        color: black !important;
        font-size: 24px;
    }
}