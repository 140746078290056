@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.upcoming-movies {
  padding: 20px;
  background-color: #0000;
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
  /* height: 850px; */
}

.upcoming-movies h2 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
  border-bottom: 2px solid #e50914;
  padding-bottom: 5px;
  display: inline-block;
}

.upcoming-movies-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.movie-card {
  background-color: #0d0d0d;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(50% - 20px); /* Two items per row with 20px gap */
  display: flex;
  flex-direction: column;
}

.movie-card img {
  width: 100%;
  height: auto;
}

.movie-info {
  padding: 15px;
}

.movie-info h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.movie-info p {
  font-size: 14px;
  margin-bottom: 10px;
}

.movie-info a {
  color: #ffcc00;
  text-decoration: none;
}

.movie-info a:hover {
  text-decoration: underline;
}

.upcoming-movies-container.single-movie {
  display: flex;
}

.upcoming-movies-container.single-movie .movie-card {
  max-width: 49%;
}

@media screen and (max-width: 480px) {
  .upcoming-movies h2 {
    font-size: 25px;
    border-bottom: 2px solid #e50914;
    padding-bottom: 5px;
    display: inline-block;
  }

  .upcoming-movies{
    height: max-content;
    text-align: center;
  }

  .movie-card {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  .movie-info h3 {
    font-size: 16px;
  }

  .movie-info p {
    font-size: 12px;
  }
}