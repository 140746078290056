@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.contact-form {
  padding: 40px 20px;
  background-color: #0d0d0d;
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
  text-align: center;
  
}

.contact-form h2 {
  margin-bottom: 30px;
  font-size: 28px;
}

.contact-form .ant-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form .ant-form-item {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.contact-form .ant-form-item-textarea {
  margin-bottom: 30px;  
}

.contact-form .ant-input,
.contact-form .ant-input-textarea {
  border-radius: 5px;
  background: linear-gradient(to bottom, #22262C 0%, #000000 100%);
  color: #ffffff;
  padding: 10px;
  border: none;
  box-shadow: none;
}

.contact-form .ant-input::placeholder,
.contact-form .ant-input-textarea::placeholder {
  color: #ffffff;
}

.contact-form .ant-input:focus,
.contact-form .ant-input-textarea:focus {
  border-color: #00cfff;
  box-shadow: 0 0 5px rgba(0, 207, 255, 0.5);
}

.contact-form .ant-btn {
  background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%);
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  padding: 0 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-form .ant-btn:hover {
  background: linear-gradient(to bottom, #7d0222 0%, #F90343 100%) !important;
}