.footer-admin-container {
    font-family: 'Arial', sans-serif;
    padding: 2rem;
    background: #f4f4f9;
    max-width: 600px;
    margin: 2rem auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .footer-admin-container h1 {
    text-align: center;
    color: #333;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .footer-admin-current-heading {
    background: #fff;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    color: #555;
    font-size: 1rem;
    text-align: center;
  }
  
  .footer-admin-input-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .footer-admin-input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    color: #333;
  }
  
  .footer-admin-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
  
  .footer-admin-button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 0.8rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .footer-admin-button:hover {
    background: #0056b3;
  }
  
  .footer-admin-button:active {
    background: #004085;
  }
  
  .footer-admin-notice {
    margin-top: 1.5rem;
    text-align: center;
    color: #666;
    font-size: 0.9rem;
  }
  