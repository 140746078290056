@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Donegal+One&display=swap');

.live-events {
  text-align: center;
  background-color: #000;
  padding: 20px;
}

.live-events-container {
  color: white;
}

.live-events-container hr {
  visibility: hidden;
}

.live-events-container h1 {
  color: #fff;
  font-size: 60px;
  border-bottom: 2px solid #e50914;
  padding-bottom: 5px;
  display: inline-block;
}

.event-container {
  display: flex;
  justify-content: center;
  gap: 60px;
  /* margin-top: 20px; */
}

.event {
  background-color: #0d0d0d;
  text-align: center;
  border-radius: 10px;
  width: 720px;
}

.event img {
  min-width: 720px;
  height: 400px;
  border-radius: 10px;
}

.watch-now {
  margin-bottom: 30px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%);
  color: #fff;
}

.watch-now:hover {
  background: linear-gradient(to bottom, #7d0222 0%, #F90343 100%);
}

.movie-info {
  padding: 15px;
}

.movie-info h2 {
  color: #fff;
  font-weight: 100;
}

.event-container .movie-info p {
  padding-top: 10px;
  color: #fff;
  font-size: 16px;
}

.movie-info a {
  color: #ffcc00;
  text-decoration: none;
}

.movie-info a:hover {
  text-decoration: underline;
}

/* Responsive styles */

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 50px;
  }

  .event {
    width: 450px;
  }

  .event img {
    height: 450px;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
  }

  .event {
    width: 400px;
  }

  .event img {
    height: 400px;
  }
}

@media screen and (max-width: 480px) {
  .live-events-container h1 {
    font-size: 40px;
    padding: 10px;
    margin: 10px 0 20px 0;
  }

  .live-events-container hr {
    visibility: visible;
    margin-top: -40px;
  }

  .event-container {
    width: 100%;
    display: block;
  }

  .event {
    width: 100%;
  }

  .event h2 {
    font-size: 25px;
  }

  .event p {
    font-size: 16px;
  }

  .event-container .watch-now {
    width: 60%;
    margin: -10px 0 20px 0;
  }

  .event-container .event img {
    width: 100% !important;  
    min-width: 100%;
    height: 250px;
  }

  .event{
    width: 100%;
    max-width: none;
  }
}

/* Overlay that covers the entire screen */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  /* Darker transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensures it's on top of other content */
}

/* Overlay to darken the background when the popup is open */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup container with smooth transition and rounded corners */
.popup-box {
  background: #fff;
  border-radius: 15px;
  padding: 20px 30px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  transform: scale(0.95);
  animation: popup-animation 0.3s forwards;
}

/* Smooth scale-up animation for popup */
@keyframes popup-animation {
  from {
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Delete icon in the top right corner */
.delete-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  color: #ff4d4f;
  font-size: 20px;
  cursor: pointer;
}

/* Popup title styling */
.popup-box h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

/* Paragraph styling for event details */
.popup-box p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
  line-height: 1.6;
}

/* Styling for the button container in the popup */
.popup-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

/* Button style for 'Buy Ticket' */
.close-popup {
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  font-size: 16px;
}

/* Hover effect on buttons */
.popup-box button:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease;
}