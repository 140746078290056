@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  font-family: 'DM Sans', sans-serif;
}

.registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.registration .overlay {
  min-height: 100vh;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: absolute;
  top: 0;
}

.registration img {
  width: 100%;
  height: 100vh;
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.registration-heading {
  color: white;
  font-size: 24px;
  margin-bottom: 30px;
  text-align: left;
}

.registration-box {
  margin-top: -100px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  text-align: center;
  width: 800px;
  height: 550px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  z-index: 1;
  border: 2px solid #ffffff;
}

.signin-container {
  margin: 10px 0;
}

.google-signin {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121418;
  color: white;
  padding: 10px;
  margin-right: 110px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 70%;
}

.google-signin .icon {
  margin-right: 20px;
  font-size: 30px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px 150px;
  color: #ccc;
}

.separator span {
  padding: 0 15px;
  background: rgba(0, 0, 0, 0.8);
  font-size: 18px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.input-field,
.input-field1 {
  width: calc(50% - 10px);
  padding: 20px;
  margin: 10px 0;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #121418;
  color: white;
  text-align: left;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.terms-container {
  text-align: center;
  margin: 20px 0;
  color: #ffffff;
  margin-bottom: 50px;
}

.terms-container input {
  margin-right: 5px;
}

.terms-container a {
  color: #1e90ff;
}

.submit-button {
  background: #20448A;
  color: white;
  padding: 20px 40px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  z-index: 1;
  border: 1px solid #ffffff;
}

.signup-button {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.form-row .toggle-visibility {
  /* position: relative; */
  transform: translateY(30%) translateX(-100%) !important;
  cursor: pointer;
}

.phone-input-container {
  width: calc(100% - 20px);
  margin: 15px 0;
}

.phone-input-container .form-control {
  width: 44% !important;
  height: 50px !important;
  background: #121418 !important;
  color: white !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  padding: 10px !important;
  margin: 0 320px 0 0 !important;
  font-size: 14px !important;
}

.phone-input-container .flag-dropdown {
  background: #121418 !important;
  border: 1px solid #ccc !important;
  border-radius: 5px 0 0 5px !important;
}

.phone-input-container .phone-number {
  border-left: 1px solid #ccc !important;
}

/* Media Queries */

@media (min-width: 768px) {
  .registration-box {
    padding: 35px;
  }

  .google-signin {
    margin-left: 100px;
  }

  .form-row {
    flex-direction: row;
    justify-content: space-between;
  }

  .input-field,
  .input-field1 {
    width: calc(50% - 10px);
  }

  .submit-button {
    padding: 20px 40px;
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .registration-box {
    width: 90%;
    padding: 35px;
    height: max-content;
  }

  .registration-heading {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .google-signin {
    font-size: 14px;
    padding: 8px;
    margin-right: 10px;
    margin-top: 20px;
  }

  .google-signin .icon {
    font-size: 24px;
    margin-right: 10px;
  }

  .separator {
    margin: 10px 0;
  }

  .separator span {
    font-size: 16px;
    padding: 0 10px;
  }

  .input-field,
  .input-field1 {
    width: calc(100% - 20px);
    padding: 15px;
    margin: 15px 0;
    font-size: 14px;
  }

  .form-row {
    display: block;
  }

  .terms-container {
    margin: 10px 0;
    font-size: 16px;
  }

  .submit-button {
    padding: 15px 25px;
    font-size: 16px;
    width: 100%;
    max-width: 150px;
  }

  .phone-input-container .form-control {
    width: 100% !important;
    font-size: 14px !important;
    padding: 10px !important;
  }

  .password-container input {
    font-size: 14px;
    height: 50px;
  }

  .password-container .toggle-visibility {
    font-size: 18px;
  }
}

@media (max-width: 479px) {
  .registration-box {
    width: 90% !important;
    padding: 20px;
  }

  .registration-heading {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .google-signin {
    font-size: 15px;
    padding: 6px;
    margin-right: 50px !important;
    margin-top: 20px;
  }

  .google-signin .icon {
    font-size: 20px;
    margin-right: 8px;
  }

  .separator span {
    font-size: 15px;
    padding: 0 8px;
  }

  .input-field,
  .input-field1 {
    width: calc(100% - 20px);
    padding: 15px;
    margin: 15px 0 !important;
    font-size: 15px;
  }

  .form-row {
    display: block;
  }

  .terms-container {
    margin: 8px 0;
    font-size: 15px;
  }

  .submit-button {
    padding: 10px 30px;
    font-size: 12px;
    width: 100%;
    max-width: 120px;
  }

  .phone-input-container .form-control {
    font-size: 12px !important;
    padding: 8px !important;
  }

  .password-container input {
    font-size: 12px;
    height: 45px;
  }

  .password-container .toggle-visibility {
    font-size: 16px;
  }
}