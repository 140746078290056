@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Donegal+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.event-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0d0d0d;
  color: white;
  padding: 20px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.event-info {
  flex: 1;
  padding-right: 20px;
  margin-left: 15px;
}

.event-info h1 {
  font-size: 36px;
  margin: 20px;
}

.event-info p {
  margin-bottom: 10px;
  font-size: 14px;
}

.event-info .event-rating {
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 20px;
  width: 33%;
}

.event-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ticket {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
}

.event-info .event-actions p {
  margin: 10px 0 0 10px;
  font-size: 18px;
}

.event-poster {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.poster-container {
  position: relative;
  max-width: 100%;
}

.event-poster img {
  max-width: 100%;
  border-radius: 10px;
  transition: filter 0.3s ease;
}

.event-details-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(5, 13, 20, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.poster-container:hover img {
  filter: blur(5px);
}

.poster-container:hover .event-details-overlay {
  opacity: 1;
}

.ticketImg {
  width: 150px;
  height: 80px;
  margin-top: 10px;
  margin-left: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ticketImg:hover {
  cursor: pointer;
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .event-actions {
    padding-left: 20px;
    flex-direction: column;
  }

  .watch-trailer,
  .ticket {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .event-info {
    padding-right: 10px;
  }
}

@media (max-width: 768px) {
  .event-details {
    flex-direction: column;
    padding: 15px;
  }

  .event-info {
    padding-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .event-poster {
    width: 100%;
    margin-bottom: 20px;
  }

  .event-poster img {
    max-width: 80%;
  }

  .event-actions {
    padding-left: 0;
    justify-content: center;
  }

  .event-info .event-rating {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .event-info h1 {
    font-size: 28px;
  }

  .event-info p {
    font-size: 14px;
  }

  .event-info .event-rating {
    border: 1px solid white;
    border-radius: 10px;
    /* padding: 10px; */
    margin: 0 10px 10px 0;
  }

  .event-poster img {
    min-width: 100%;
    min-height: 250px;
    transition: filter 0.3s ease;
  }

  .ticketImg {
    width: 150px;
    height: 80px;
    margin: 12px 0 0 0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .ticketImg:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  .watch-trailer {
    width: 100%;
  }

  .poster-container:hover img {
    filter: blur(5px);
  }
  
  .poster-container:hover .event-details-overlay {
    opacity: 1;
  }
}
