@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.header .overlay {
  width: 100%;
  height: 70vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.header {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #364d79, #000);
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel-overlay {
  width: 100%;
}

.carousel-img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.ticket-overlay {
  position: absolute;
  bottom: 100px;
  left: 100px;
  max-width: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.ticket-img {
  width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Initial shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ticket-overlay:hover .ticket-img {
  transform: scale(1.1); /* Slightly enlarge the ticket on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
}

@media (max-width: 768px) {
  .header {
    height: 70vh;
  }

  .ticket-overlay {
    bottom: 10px;
    left: 10px;
    max-width: 150px;
  }

  .carousel-overlay p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .header {
    height: 30vh;
  }

  .header-container .overlay{
    height: 30vh;
  }

  .carousel-overlay {
    height: 30vh;
  }

  .ticket-overlay {
    bottom: 10px;
    left: 10px;
    max-width: 100px;
  }

  .carousel-overlay .carousel-img {
    height: 250px;
    width: 100%;
    object-fit: fill;
  }
}
