@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.now-showing {
  padding: 20px;
  background-color: #000;
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
}

.now-showing h2 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
}

.now-showing-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.movie-card {
  background-color: #0d0d0d;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(50% - 20px); 
  display: flex;
  flex-direction: column;
}

.movie-card .react-player{
  width: 100% !important;
  height: auto !important; 
}

.movie-info {
  padding: 15px;
}

.movie-info h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.movie-info p {
  font-size: 14px;
  margin-bottom: 10px;
}

.movie-info a {
  color: #ffcc00;
  text-decoration: none;
}

.movie-info a:hover {
  text-decoration: underline;
}

.single-trailer .movie-card {
  flex: 0 1 60%; 
  max-width: 900px;
  margin: 5px 0;
}

.single-trailer .movie-card video {
  height: 500px;
}

/* Responsive adjustments */

@media (max-width: 1024px) {
  .movie-card {
    flex: 1 1 calc(100% - 20px);
  }

  .movie-card video {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .now-showing {
    padding: 15px;
  }

  .now-showing h2 {
    text-align: center;
    font-size: 22px;
  }

  .movie-card {
    flex: 1 1 calc(100% - 20px);
  }

  .movie-card video {
    height: 300px;
  }

  .movie-info h3 {
    font-size: 16px;
  }

  .movie-info p {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .movie-card {
    min-width: 100%;
    flex: 1 1 calc(100% - 10px);
    background-color: #0d0d0d;
  }

  .movie-card .react-player{
    width: 100% !important;
    height: auto !important; 
  }
  
  .movie-info h3 {
    font-size: 16px;
  }

  .movie-info p {
    font-size: 14px;
  }
}