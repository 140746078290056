.site-layout {
  background: #fff;
}

.site-layout-background {
  background: #fff;
  padding: 24px;
  margin: 0;
  min-height: 360px;
}
