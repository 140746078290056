.logout-container {
  padding: 5px;
  text-align: center;
  margin-top: 20px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.logo img {
  width: 100px;
}

.logout-container {
  position: absolute;
  bottom: 60px;
  width: 100%;
  text-align: center;
}

.logout-container .anticon-logout {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}