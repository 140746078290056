.live-watch {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #0d0d0d;
}

.live-watch h2 {
  font-size: 2.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 2px solid #e50914;
  padding-bottom: 5px;
}

.live-watch p {
  color: #bbb;
  font-size: 1rem;
  text-align: center;
}

.event-select-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.event-select {
  padding: 12px 30px;
  font-size: 1rem;
  border-radius: 5px;
  border: 2px solid #333;
  background-color: #2b2b2b;
  color: #e0e0e0;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  outline: none;
  width: 100%;
  max-width: 300px;
}

.event-select:hover {
  border-color: #b30b0b;
}

/* .live-watch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0d0d0d;
} */

.live-watch-container p {
  color: #fff;
  font-size: 1.2rem;
}

/* .live-watch-container video {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

/* .player-wrapper {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
  padding: 20px;
} */

/* Responsive Adjustments */
@media (max-width: 768px) {
  .live-watch h2 {
    font-size: 1.8rem;
  }

  .event-select {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .live-watch-container iframe {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .live-watch h2 {
    font-size: 1.5rem;
  }

  .event-select-container{
    max-width: 200px;
  }

  .event-select {
    font-size: 0.8rem;
    padding: 6px 10px;
  }

  .live-watch-container iframe {
    width: 100% !important;
  }

  .live-watch p {
    font-size: 0.9rem;
  }
}