@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Donegal+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.stream {
  text-align: center;
  background-color: #000;
  padding: 20px;
}

.stream-container {
  color: white;
}

.stream-container h1 {
  color: #fff;
  font-size: 60px;
  border-bottom: 2px solid #e50914;
  padding-bottom: 5px;
  display: inline-block;
}

.stream-container hr {
  visibility: hidden;
}

.movie-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 30px;
}

.movie {
  background-color: #0d0d0d;
  text-align: center;
  border-radius: 10px;
  width: 720px;
}

.movie-container .movie img {
  width: 720px;
  height: 400px;
  border-radius: 10px;
}

.single-movie .movie img {
  max-width: 100%;
  min-height: 50%;
}

.movie h2 {
  color: #fff;
  font-weight: 100;
  padding-top: 10px;
}

.movie p {
  padding-top: 10px;
  color: #fff;
}

.buttons {
  margin-top: 10px;
}

.watch-trailer,
.buy-tickets {
  padding: 10px 20px;
  margin: 0 20px 30px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #700404;
  background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%);
}

.watch-trailer:hover,
.buy-tickets:hover {  
  background: linear-gradient(to bottom, #7d0222 0%, #F90343 100%);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  max-width: 720px;
  min-height: 350px;
  border-radius: 10px;
  position: relative;
  z-index: 1001;
  outline: none;
}

.close-button {
  position: absolute;
  top: 0;
  right: 8px;
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modal-content .trailer-video {
  width: 100%;
  max-width: 720px;
  min-height: 100%; 
  border-radius: 10px;
}

/* Responsive styles */

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
    padding: 15px;
  }

  .movie-container {
    flex-direction: column;
    align-items: center;
  }

  .movie {
    width: 90%;
    max-width: 400px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .watch-trailer,
  .buy-tickets {
    width: 80%;
    margin: 5px 0;
  }
}

@media (max-width: 480px) {

  .stream-container { 
    height: 100%;
  }

  .stream-container h1 {
    font-size: 40px;
    padding: 10px;
    margin: 10px 0 0 0 !important;
  }

  .stream-container hr {
    visibility: visible;
  }

  .movie-container .movie img{
    width: 100%;  
    max-height: 250px;
  }

  .movie {
    width: 100%;
    max-width: none;
    margin-bottom: 30px;
  }

  .movie h2 {
    font-size: 25px;
  }

  .movie p {
    font-size: 16px;
  }

  .buttons {
    width: 100%;
  }

  .buttons .watch-trailer,
  .buttons .buy-tickets {
    width: 60%;
    margin: 0 0 20px 0;
    cursor: pointer;
    background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%);
  }

  .modal-content {
    min-height: 200px;
  }

  .modal-content .trailer-video {
    width: 100% !important;
    height: 100% !important;
  }
}