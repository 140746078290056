.admin-movie-management h2 {
  font-size: 30px;
  text-align: center;
  color: #20448A;
}

.movie-management-container {
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin: 30px 10px;
  width: 90%;
  box-sizing: border-box;
}

.video-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;
  margin-left: 100px;
}

.video-container h3 {
  font-size: 20px;
  text-align: left;
}

.movie-management-details {
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.upload-image {
  margin-top: 20px;
}

.details-form .ant-form-item {
  margin-bottom: 15px;
  margin-top: 20px;
}

.form-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.button-delete1 {
  margin-top: 10px;
}

.btn-movie-management {
  width: 100%;
}

.select-item-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.select-item-container .ant-form-item {
  width: 50%;
}

.select-item-container .ant-select {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .movie-management-container {
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
  }

  .admin-movie-management h2 {
    font-size: 20px;
  }

  .video-container {
    width: 100%;
    margin-right: 11.3rem;
  }

  .video-container video {
    width: 140%;
  }

  .movie-card,
  .movie-management-details {
    margin-top: 0;
  }

  .form-buttons {
    flex-direction: row;
  }

  .btn-movie-management {
    width: auto;
  }
}
