@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Donegal+One&display=swap');

.checkout-container {
    padding: 20px;
    background-color: #000;
}

.checkout-container .form-title {
    text-align:  center !important; 
    color: #fff;
    font-size: 50px;
    font-family: 'Donegal One', serif;
    font-weight: 200;
}

.details-card,
.cart-card {
    border-radius: 8px;
    padding: 20px;
    background-color: #0d0d0d;
    border: 1px solid #ffffff;
    /* text-align: center !important; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 30px;
    font-family: 'DM Mono', monospace;
    font-weight: 700;
    color: #fff;
}

.input-field::placeholder,
.select-field::placeholder {
    color: #ffffff;
    opacity: 0.8;
}

.details-card .ant-form-item-label>label {
    font-weight: bold;
    color: #fff;
}

.details-card .input-field {
    border-radius: 5px;
    background: linear-gradient(to bottom, #22262C 0%, #000000 100%);
    color: #ffffff;
    width: 100%;
    height: 20px;
}

.details-card .submit-btn{
    background: linear-gradient(to bottom, #F90343 0%, #7d0222 100%) ;
    color: #ffffff;
}

.details-card .submit-btn:hover {
    background: linear-gradient(to bottom, #7d0222 0%, #F90343 100%) !important;
}

.country-selector .custom-select__control {
    background: linear-gradient(to bottom, #22262C 0%, #000000 100%);
    color: #ffffff;
    border-color: #ffffff;
}

.country-selector .custom-select__single-value {
    color: #ffffff;
}

.country-selector .custom-select__menu {
    background: #22262C;
    color: #ffffff;
}

.country-selector .custom-select__option {
    background-color: #22262C;
    color: #ffffff;
}

/* .country-selector .input-field::placeholder {
    color: #ffffff;
}

.country-selector .select-field::placeholder{
    color: #ffffff;
} */

.country-selector .custom-select__option--is-selected {
    background-color: #333;
    color: #ffffff;
}

.country-selector .custom-select__option--is-focused {
    background-color: #444;
    color: #ffffff;
}

.checkout-container .ant-checkbox-wrapper {
    color: #ffffff;
}

.checkout-container .details-card p{
    text-align: left !important;
    font-size: large !important;
    color: #333 !important;
}

.cart-card p {
    margin: 5px 0;
    font-size: 16px;
    color: #fff;
}

.cart-card .ant-typography {
    margin-top: 10px;
    display: block;
    font-size: 18px;
    color: #595959;
    font-weight: bold;
}

.movie h3 {
    font-size: 26px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    margin-top: -20px;
    border-bottom: 2px solid #e50914 !important;
}

.secure-payment-description {
    background-color: #0d0d0d;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    margin-bottom: 25px;
}

.secure-payment-description p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
}

.secure-payment-description h3 {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 15px;
}

.secure-payment-description img {
    max-width: 70px;
    margin: 10px 20px;
    vertical-align: middle;
}

.expiration-notice {
    background-color: #ffcc00;
    color: #000;
    padding: 10px;
    margin-top: 15px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
}


@media (max-width: 768px) {
    .checkout-container .form-title {
        font-size: 40px;
    }

    .details-card,
    .cart-card {
        margin-bottom: 20px;
        padding: 15px;
    }

    .card-title {
        font-size: 20px;
    }

    .movie h3 {
        font-size: 28px;
    }

    .movie h3::after {
        height: 3px;
    }

    .checkout-container .ant-row {
        flex-direction: column;
    }

    .checkout-container .ant-col {
        width: 100%;
    }

    .details-card .input-field {
        height: 40px;
    }

    .checkout-container .ant-form-item-label > label {
        font-size: 16px;
    }

    .cart-card p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .checkout-container .form-title {
        font-size: 30px;
    }

    .details-card
    {
        padding: 10px;
        width: 150%;
    }

    .cart-card{
        min-width: 380px;
    }

    .card-title {
        font-size: 18px;
        text-align: center !important;
    }

    .movie h3 {
        font-size: 24px;
        border-bottom: 2px solid #e50914 !important;
        padding-bottom: 5px;
        display: inline-block;
    }

    .checkout-container .ant-form-item-label > label {
        font-size: 14px;
    }

    .checkout-container .ant-row {
        padding: 0;
    }
}