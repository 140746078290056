.admin-movie-management h2 {
  font-size: 30px;
  margin-top: 20px;
  text-align: center;
  color: #20448A;
}

.movie-profile-card {
  display: flex;
  justify-content: space-between;
  align-items: stretch; 
  gap: 20px;
  margin-top: 3rem;
}

.profile-card {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.profile-card .ant-card-body {
  flex-grow: 1; 
}

.movie-management-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 30px 10px;
  width: 100%;
  box-sizing: border-box; 
}

.movie-management-details, .movie-profile-management {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.movie-card img {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.details-form .ant-form-item {
  margin-bottom: 15px;
  color: black;
}

.details-form .ant-input[disabled] {
  background-color: #f5f5f5;
  color: #333;
}

.progress-modal {
  text-align: center;
  
}

.progress-modal .ant-progress {
  margin-bottom: 20px;
  margin-top: 40px;
}

.progress-modal-text {
  font-size: 16px;
  color: #595959;
}

@media screen and (max-width: 480px) {
  .movie-profile-card {
    flex-direction: column;
    gap: 15px;
  }

  .profile-card {
    width: 100%;
  }

  .movie-profile-management{
    width: 130%;
  }
}
