@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Refund Policy Section Styling */
.refund-policy-section {
    background: linear-gradient(135deg, #1c1c1c, #0d0d0d);
    color: #e0e0e0;
    font-family: 'Roboto', sans-serif;
    /* padding-top: 60px; */
}

/* Content Container */
.refund-policy-content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    background-color: #111111;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    line-height: 1.8;
}

/* Title Styling */
.refund-policy-title {
    text-align: center;
    font-weight: 700;
    font-size: 2.5em;
    color: #e50914 !important;
    margin-bottom: 24px;
    letter-spacing: 1px;
}

/* Divider Styling */
.refund-policy-divider {
    margin: 20px 0;
    border-top: 1px solid #333;
    color: #ffffff;
}

/* Section Subtitle Styling */
.refund-policy-subtitle {
    font-size: 1.8em;
    color: #e50914 !important;
    margin-top: 30px;
    font-weight: 600;
}

/* Paragraph Styling */
.refund-policy-paragraph {
    font-size: 1.1em;
    color: #cfcfcf;
    text-align: justify;
    line-height: 1.7;
    padding-left: 10px;
    padding-right: 10px;
}

/* Italicized Text (Effective Date) */
.refund-policy-italic {
    text-align: center;
    font-style: italic;
    font-size: 1.1em;
    color: #9e9e9e;
}

/* List Item Styling */
.refund-policy-paragraph strong {
    color: #ffffff;
    font-weight: 700;
}

/* Button Styling for Scrolling */
.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #e50914;
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.scroll-to-top:hover {
    transform: translateY(-3px);
    background-color: #bf0e12;
}

.scroll-to-top svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    fill: #ffffff;
}
